
import { mdiMenu, mdiClose, mdiFilmstrip, mdiTelevision, mdiAccountMultiple, mdiMovieOpen } from '@mdi/js'
export default {
  name: 'NavMenu',
  data() {
    return {
      svgClose: mdiClose,
      svgMenu: mdiMenu,
      svgFilmstrip: mdiFilmstrip,
      svgTelevision: mdiTelevision,
      svgAccountMultiple: mdiAccountMultiple,
      svgMovieOpen: mdiMovieOpen,
      dialog: false,
      categories: [
        {
          name: "Movies",
          icon: this.svgFilmstrip,
          items: [
            {
              heading: "In Cinemas Now",
              link: "/movies/featured/now_playing"
            },
            {
              heading: "Top Rated Movies",
              link: "/movies/featured/top_rated"
            },
            {
              heading: "Most Popular Movies",
              link: "/movies/featured/popular"
            },
            {
              heading: "Browse Movies by Genre",
              link: "/movies/genres"
            },
            {
              heading: "Coming Soon to Cinemas",
              link: "/movies/featured/upcoming"
            }
          ]
        },
        {
          name: "TV",
          icon: this.svgTelevision,
          items: [
            {
              heading: "What's On TV",
              link: "/tvshows/featured/airing_today"
            },
            {
              heading: "What's Airing Today",
              link: "/tvshows/featured/on_the_air"
            },
            {
              heading: "Top Rated TV",
              link: "/tvshows/featured/top_rated"
            },
            {
              heading: "Most Popular TV",
              link: "/tvshows/featured/popular"
            },
            {
              heading: "Browse TV by Genre",
              link: "/tvshows/genres"
            }
          ]
        },
        {
          name: "Actors",
          icon: this.svgAccountMultiple,
          items: [
            {
              heading: "Best Actors",
              link: "/actors/featured/popular"
            }

          ]
        }
        ,
        {
          name: "About",
          icon: this.svgMovieOpen,
          items: [
            {
              heading: "News",
              link: "/news"
            },
            {
              heading: "Contact",
              link: "/contact"
            },

            {
              heading: "Cookies",
              link: "/cookies"
            },
            {
              heading: "Terms of Use",
              link: "/terms"
            },
            {
              heading: "Privacy Policy",
              link: "/privacy-policy"
            }
          ]
        },
      ]
    }
  }
}
