
import Watchlist from '@/components/nav/Watchlist.vue'
import Search from '@/components/nav/search/Search.vue'
import SearchSmall from '@/components/nav/search/SearchSmall.vue'
import NavMenu from '@/components/nav/NavMenu.vue'
import { mapState } from 'vuex'

export default {
  name: 'NavBar',
  components: {
    Watchlist,
    Search,
    SearchSmall,
    NavMenu
  },
  computed: {
    ...mapState(["searchOpen"])
  }
}
