
import axios from 'axios'

export default {
  data() {
    return {
      searchInput: '',
      searchResults: [],
      showResults: false,
      page: 1,
    }
  },
  computed: {
    url() {
      return `https://api.themoviedb.org/3/search/multi?api_key=${process.env.apikey}&language=${process.env.lang}&include_adult=false&page=${this.page}&query=${this.searchInput}`;
    }
  },
  methods: {
    async searchMovies() {

      this.searchResults = []; //clear the old results
      this.showResults = true;
      const data = axios.get(
        `https://api.themoviedb.org/3/search/multi?api_key=${process.env.apikey}&language=${process.env.lang}&include_adult=false&page=1&query=${this.searchInput}`
      )
      const result = await data
      result.data.results.forEach((movie) => {
        this.searchResults.push(movie)

      })

    },
    /* Infinite Scroll */
    infiniteScroll($state) {
      setTimeout(() => {
        this.page++
        axios.get(this.url)
          .then((response) => {
            if (response.data.results.length > 1) {
              response.data.results.forEach((item) => this.searchResults.push(item))
              $state.loaded()
            } else {
              $state.complete()
            }
          }).catch((err) => { console.log(err) })
      }, 500)
    },
    clearSearch() {
      this.searchInput = ''
      this.searchResults = []
      this.showResults = false
    },
  },

}



