import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43309c2f = () => interopDefault(import('../pages/123movies.vue' /* webpackChunkName: "pages/123movies" */))
const _7cd7c672 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _00e2a152 = () => interopDefault(import('../pages/actors/index.vue' /* webpackChunkName: "pages/actors/index" */))
const _a106494c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _47642a49 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _dd5bd188 = () => interopDefault(import('../pages/movies/index.vue' /* webpackChunkName: "pages/movies/index" */))
const _261a564c = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1d53f388 = () => interopDefault(import('../pages/people/index.vue' /* webpackChunkName: "pages/people/index" */))
const _245a834d = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _f2850bbe = () => interopDefault(import('../pages/putlocker.vue' /* webpackChunkName: "pages/putlocker" */))
const _33282291 = () => interopDefault(import('../pages/recent.vue' /* webpackChunkName: "pages/recent" */))
const _e96c947e = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _1c3e632c = () => interopDefault(import('../pages/tvprograms/index.vue' /* webpackChunkName: "pages/tvprograms/index" */))
const _f58e0dde = () => interopDefault(import('../pages/tvshows/index.vue' /* webpackChunkName: "pages/tvshows/index" */))
const _ed8ff5d6 = () => interopDefault(import('../pages/movies/258489-the-legend-of-tarzan-2016.vue' /* webpackChunkName: "pages/movies/258489-the-legend-of-tarzan-2016" */))
const _25798559 = () => interopDefault(import('../pages/movies/genres/index.vue' /* webpackChunkName: "pages/movies/genres/index" */))
const _799dd9e4 = () => interopDefault(import('../pages/tvshows/genres/index.vue' /* webpackChunkName: "pages/tvshows/genres/index" */))
const _97be90c2 = () => interopDefault(import('../pages/tv/featured/popular.vue' /* webpackChunkName: "pages/tv/featured/popular" */))
const _2ab1a8e0 = () => interopDefault(import('../pages/tv/featured/top_rated.vue' /* webpackChunkName: "pages/tv/featured/top_rated" */))
const _c8ff6e38 = () => interopDefault(import('../pages/actors/featured/_id.vue' /* webpackChunkName: "pages/actors/featured/_id" */))
const _ed866f40 = () => interopDefault(import('../pages/actors/keywords/_id.vue' /* webpackChunkName: "pages/actors/keywords/_id" */))
const _cdcb1e8a = () => interopDefault(import('../pages/en/movie/_slug.vue' /* webpackChunkName: "pages/en/movie/_slug" */))
const _49836260 = () => interopDefault(import('../pages/en/tv/_id/index.vue' /* webpackChunkName: "pages/en/tv/_id/index" */))
const _21464d1d = () => interopDefault(import('../pages/en/tv/_slug.vue' /* webpackChunkName: "pages/en/tv/_slug" */))
const _c44fb542 = () => interopDefault(import('../pages/movies/featured/_id.vue' /* webpackChunkName: "pages/movies/featured/_id" */))
const _542b0a01 = () => interopDefault(import('../pages/movies/genres/_id.vue' /* webpackChunkName: "pages/movies/genres/_id" */))
const _2731306b = () => interopDefault(import('../pages/movies/keywords/_slug.vue' /* webpackChunkName: "pages/movies/keywords/_slug" */))
const _1c070c2c = () => interopDefault(import('../pages/tvshows/featured/_id.vue' /* webpackChunkName: "pages/tvshows/featured/_id" */))
const _3de1054c = () => interopDefault(import('../pages/tvshows/genres/_id.vue' /* webpackChunkName: "pages/tvshows/genres/_id" */))
const _03928e36 = () => interopDefault(import('../pages/tvshows/keywords/_slug.vue' /* webpackChunkName: "pages/tvshows/keywords/_slug" */))
const _caa4ffaa = () => interopDefault(import('../pages/en/actor/_id/_slug.vue' /* webpackChunkName: "pages/en/actor/_id/_slug" */))
const _3d44d226 = () => interopDefault(import('../pages/en/movie/_id/_slug.vue' /* webpackChunkName: "pages/en/movie/_id/_slug" */))
const _4cf0a4f0 = () => interopDefault(import('../pages/en/tv/_id/_slug.vue' /* webpackChunkName: "pages/en/tv/_id/_slug" */))
const _20d1e85d = () => interopDefault(import('../pages/actor/_id/index.vue' /* webpackChunkName: "pages/actor/_id/index" */))
const _044fe3e2 = () => interopDefault(import('../pages/actors/_slug.vue' /* webpackChunkName: "pages/actors/_slug" */))
const _1ce1ed76 = () => interopDefault(import('../pages/crew/_id/index.vue' /* webpackChunkName: "pages/crew/_id/index" */))
const _1b0893b8 = () => interopDefault(import('../pages/films/_slug.vue' /* webpackChunkName: "pages/films/_slug" */))
const _7ef786b6 = () => interopDefault(import('../pages/movie/_slug.vue' /* webpackChunkName: "pages/movie/_slug" */))
const _e0c91418 = () => interopDefault(import('../pages/movies/_slug.vue' /* webpackChunkName: "pages/movies/_slug" */))
const _2463b504 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _1b9d5240 = () => interopDefault(import('../pages/people/_slug.vue' /* webpackChunkName: "pages/people/_slug" */))
const _3e3328c3 = () => interopDefault(import('../pages/people/_slug/index.vue' /* webpackChunkName: "pages/people/_slug/index" */))
const _404208f4 = () => interopDefault(import('../pages/person/_slug.vue' /* webpackChunkName: "pages/person/_slug" */))
const _86b18bce = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _87bfcd1a = () => interopDefault(import('../pages/tv/_slug.vue' /* webpackChunkName: "pages/tv/_slug" */))
const _1faba5bc = () => interopDefault(import('../pages/tvprograms/_slug.vue' /* webpackChunkName: "pages/tvprograms/_slug" */))
const _3b74d225 = () => interopDefault(import('../pages/tvprograms/_slug/index.vue' /* webpackChunkName: "pages/tvprograms/_slug/index" */))
const _f8fb506e = () => interopDefault(import('../pages/tvshows/_slug.vue' /* webpackChunkName: "pages/tvshows/_slug" */))
const _1f1b4715 = () => interopDefault(import('../pages/actor/_id/_slug.vue' /* webpackChunkName: "pages/actor/_id/_slug" */))
const _204f3006 = () => interopDefault(import('../pages/crew/_id/_slug.vue' /* webpackChunkName: "pages/crew/_id/_slug" */))
const _7c9acde0 = () => interopDefault(import('../pages/movie/_id/_slug.vue' /* webpackChunkName: "pages/movie/_id/_slug" */))
const _c1514244 = () => interopDefault(import('../pages/tv/_id/_slug.vue' /* webpackChunkName: "pages/tv/_id/_slug" */))
const _5b878d26 = () => interopDefault(import('../pages/news/_year/_month/_day/_slug.vue' /* webpackChunkName: "pages/news/_year/_month/_day/_slug" */))
const _12413a30 = () => interopDefault(import('../pages/news/_year/_month/_day/_slug/index.vue' /* webpackChunkName: "pages/news/_year/_month/_day/_slug/index" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/123movies",
    component: _43309c2f,
    name: "123movies"
  }, {
    path: "/about",
    component: _7cd7c672,
    name: "about"
  }, {
    path: "/actors",
    component: _00e2a152,
    name: "actors"
  }, {
    path: "/contact",
    component: _a106494c,
    name: "contact"
  }, {
    path: "/cookies",
    component: _47642a49,
    name: "cookies"
  }, {
    path: "/movies",
    component: _dd5bd188,
    name: "movies"
  }, {
    path: "/news",
    component: _261a564c,
    name: "news"
  }, {
    path: "/people",
    component: _1d53f388,
    name: "people"
  }, {
    path: "/privacy-policy",
    component: _245a834d,
    name: "privacy-policy"
  }, {
    path: "/putlocker",
    component: _f2850bbe,
    name: "putlocker"
  }, {
    path: "/recent",
    component: _33282291,
    name: "recent"
  }, {
    path: "/terms",
    component: _e96c947e,
    name: "terms"
  }, {
    path: "/tvprograms",
    component: _1c3e632c,
    name: "tvprograms"
  }, {
    path: "/tvshows",
    component: _f58e0dde,
    name: "tvshows"
  }, {
    path: "/movies/258489-the-legend-of-tarzan-2016",
    component: _ed8ff5d6,
    name: "movies-258489-the-legend-of-tarzan-2016"
  }, {
    path: "/movies/genres",
    component: _25798559,
    name: "movies-genres"
  }, {
    path: "/tvshows/genres",
    component: _799dd9e4,
    name: "tvshows-genres"
  }, {
    path: "/tv/featured/popular",
    component: _97be90c2,
    name: "tv-featured-popular"
  }, {
    path: "/tv/featured/top_rated",
    component: _2ab1a8e0,
    name: "tv-featured-top_rated"
  }, {
    path: "/actors/featured/:id?",
    component: _c8ff6e38,
    name: "actors-featured-id"
  }, {
    path: "/actors/keywords/:id?",
    component: _ed866f40,
    name: "actors-keywords-id"
  }, {
    path: "/en/movie/:slug?",
    component: _cdcb1e8a,
    name: "en-movie-slug"
  }, {
    path: "/en/tv/:id",
    component: _49836260,
    name: "en-tv-id"
  }, {
    path: "/en/tv/:slug?",
    component: _21464d1d,
    name: "en-tv-slug"
  }, {
    path: "/movies/featured/:id?",
    component: _c44fb542,
    name: "movies-featured-id"
  }, {
    path: "/movies/genres/:id",
    component: _542b0a01,
    name: "movies-genres-id"
  }, {
    path: "/movies/keywords/:slug?",
    component: _2731306b,
    name: "movies-keywords-slug"
  }, {
    path: "/tvshows/featured/:id?",
    component: _1c070c2c,
    name: "tvshows-featured-id"
  }, {
    path: "/tvshows/genres/:id",
    component: _3de1054c,
    name: "tvshows-genres-id"
  }, {
    path: "/tvshows/keywords/:slug?",
    component: _03928e36,
    name: "tvshows-keywords-slug"
  }, {
    path: "/en/actor/:id?/:slug?",
    component: _caa4ffaa,
    name: "en-actor-id-slug"
  }, {
    path: "/en/movie/:id?/:slug?",
    component: _3d44d226,
    name: "en-movie-id-slug"
  }, {
    path: "/en/tv/:id?/:slug",
    component: _4cf0a4f0,
    name: "en-tv-id-slug"
  }, {
    path: "/actor/:id",
    component: _20d1e85d,
    name: "actor-id"
  }, {
    path: "/actors/:slug",
    component: _044fe3e2,
    name: "actors-slug"
  }, {
    path: "/crew/:id",
    component: _1ce1ed76,
    name: "crew-id"
  }, {
    path: "/films/:slug?",
    component: _1b0893b8,
    name: "films-slug"
  }, {
    path: "/movie/:slug?",
    component: _7ef786b6,
    name: "movie-slug"
  }, {
    path: "/movies/:slug",
    component: _e0c91418,
    name: "movies-slug"
  }, {
    path: "/news/:slug",
    component: _2463b504,
    name: "news-slug"
  }, {
    path: "/people/:slug",
    component: _1b9d5240,
    children: [{
      path: "",
      component: _3e3328c3,
      name: "people-slug"
    }]
  }, {
    path: "/person/:slug?",
    component: _404208f4,
    name: "person-slug"
  }, {
    path: "/search/:slug?",
    component: _86b18bce,
    name: "search-slug"
  }, {
    path: "/tv/:slug?",
    component: _87bfcd1a,
    name: "tv-slug"
  }, {
    path: "/tvprograms/:slug",
    component: _1faba5bc,
    children: [{
      path: "",
      component: _3b74d225,
      name: "tvprograms-slug"
    }]
  }, {
    path: "/tvshows/:slug",
    component: _f8fb506e,
    name: "tvshows-slug"
  }, {
    path: "/actor/:id?/:slug",
    component: _1f1b4715,
    name: "actor-id-slug"
  }, {
    path: "/crew/:id?/:slug",
    component: _204f3006,
    name: "crew-id-slug"
  }, {
    path: "/movie/:id?/:slug?",
    component: _7c9acde0,
    name: "movie-id-slug"
  }, {
    path: "/tv/:id?/:slug?",
    component: _c1514244,
    name: "tv-id-slug"
  }, {
    path: "/news/:year/:month?/:day?/:slug?",
    component: _5b878d26,
    children: [{
      path: "",
      component: _12413a30,
      name: "news-year-month-day-slug"
    }]
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
