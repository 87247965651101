export default function ({ store, route, redirect }) {
    //Redirect for old movie eg. http://localhost:3000/movie/
    const urlParameters = route.params.slug.split('-');
    let searchSlug = urlParameters.join(' ');
    const searchSlugEncoded = encodeURIComponent(searchSlug);
    return store.$axios.get(`https://api.themoviedb.org/3/search/keyword?api_key=${process.env.apikey}&query=${searchSlugEncoded}&page=1`)
        .then((result) => {
            const result_id = result.data.results[0].id;
            const result_title = result.data.results[0].name;
            const redirectUrl = '/movies/keywords/' + store.$slugy(result_id + '_' + result_title)
            redirect(redirectUrl);
            return
        })
        .catch((err) => {
            redirect('/movies/');
        })
}