
import { mapState, mapActions } from 'vuex'
import { mdiMagnify,mdiAccount,mdiClose,mdiVideoImage } from '@mdi/js'


export default {
  name: 'SearchSmall',
  data() {
    return {
      svgMagnify: mdiMagnify,
      svgAccount: mdiAccount,
      svgClose: mdiClose,
      svgVideoImage: mdiVideoImage,
      searchString: "",
      result: null,
      searchResults: [],
    }
  },
  computed: {
    ...mapState(["searchOpen"]),
    formattedResults() {
      return this.searchResults.map(item => {
        return {
          ...item,
          title: item.title || item.name
        }
      })
    }
  },
  methods: {
    ...mapActions(["toggleSearchBar"]),
    async search() {
      if (!this.searchString) {
        this.searchResults = []
        return
      }
      try {
        const searchMedia = await this.$axios.$get(`https://api.themoviedb.org/3/search/multi?api_key=${process.env.apikey}&language=${process.env.lang}&query=${this.searchString}&page=1&include_adult=false`)
        this.searchResults = searchMedia.results
      } catch (err) {
        // suppress media lookup error
        // console.log(err)
      }
    },
    determineMediaType(media) {
      if (media.media_type === 'person') return 'actors'
      if (media.media_type === 'movie') return 'movies'
      return 'tvshows'
    },
    formatRole(role) {
      if (role === 'Acting') return 'Actor'
      if (role === 'Directing') return 'Director'
      if (role === 'Sound') return 'Composer'
      if (role === 'Writing') return 'Writer'
      if (role === 'Production') return 'Producer'
      if (role === 'Editing') return 'Editor'
      return role
    }
  },
  watch: {
    async searchString() {
      await this.search()
    },
    result() {
      const matchedMedia = this.formattedResults.find(media => media.title === this.result)
      const type = this.determineMediaType(matchedMedia)
      this.$router.push({ path: `/${type}/${matchedMedia.id}` })
      this.searchString = null
    }
  }
}
