
import { mapState } from 'vuex'
import { mdiMagnify, mdiAccount, mdiVideoImage } from '@mdi/js'


export default {
  name: 'Search',
  data() {
    return {
      svgMagnify: mdiMagnify,
      svgAccount: mdiAccount,
      svgVideoImage: mdiVideoImage,
      searchString: "",
      result: null,
      searchResults: [],
    }
  },
  computed: {
    formattedResults() {
      return this.searchResults.map(item => {
        return {
          ...item,
          title: item.title || item.name
        }
      })
    },


  },
  methods: {
    async search() {
      if (!this.searchString) {
        this.searchResults = []
        return
      }
      try {
        const searchMedia = await this.$axios.$get(`https://api.themoviedb.org/3/search/multi?api_key=${process.env.apikey}&language=${process.env.lang}&query=${this.searchString}&page=1&include_adult=false`)
        this.searchResults = searchMedia.results
      } catch (err) {
        // suppress media lookup error
        // console.log(err)
      }
    },
    determineMediaType(media) {
      if (media.media_type === 'person') return 'actors'
      if (media.media_type === 'movie') return 'movies'
      return 'tvshows'
    },
    resultTitle(media) {
      return media.name ? media.name : media.title
    },
    resultReleaseYear(media) {
      //if its a tv series
      if (media.name) {
        return new Date(media.first_air_date).toLocaleString('en-US', {
          year: 'numeric',
        })
      }
      //its a movie
      return new Date(media.release_date).toLocaleString('en-US', {
        year: 'numeric',
      });

    },
    formatRole(role) {
      if (role === 'Acting') return 'Actor'
      if (role === 'Directing') return 'Director'
      if (role === 'Sound') return 'Composer'
      if (role === 'Writing') return 'Writer'
      if (role === 'Production') return 'Producer'
      if (role === 'Editing') return 'Editor'
      return role
    }
  },
  watch: {
    async searchString() {
      await this.search()
    },
    result() {
      const matchedMedia = this.formattedResults.find(media => media.title === this.result)
      const type = this.determineMediaType(matchedMedia)

      if (type == 'actors') {
        this.$router.push({
          name: `${type}-slug`, params: { slug: this.$slugy(matchedMedia.id + '-' + this.resultTitle(matchedMedia)) }
        })
      } else {
        this.$router.push({
          name: `${type}-slug`, params: { slug: this.$slugy(matchedMedia.id + '-' + this.resultTitle(matchedMedia) + '-' + this.resultReleaseYear(matchedMedia)) }
        })
      }




      this.searchString = null
      document.getElementById("search-autocomplete").blur()
    }
  }
}
