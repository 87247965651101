
export default {
    data() {
        return {
            isLoading: true,
        }
    },
    props: { movie: Object },
    created() {
        this.isLoading = true;
    },
    mounted() {
        this.isLoading = false;
    },
    computed: {
        has_poster() {
            if (this.movie.poster_path != null || this.movie.profile_path != null) { return true; }
            return false;
        },
        getResultType() {
            if (this.movie.media_type) {
                return this.movie.media_type
            }
        }
    },
    methods: {
        resolve_img_url() {
            if (this.movie.poster_path != null) {
                return '/_ipx/f_webp,q_80,s_350x522/https://image.tmdb.org/t/p/w500' + this.movie.poster_path
            } else if (this.movie.profile_path != null) {
                return '/_ipx/f_webp,q_80,s_350x522/https://image.tmdb.org/t/p/w500' + this.movie.profile_path
            }
            else {
                return false;
            }
        },
        getResultTitle() {
            switch (this.getResultType) {
                case 'movie':
                    return this.movie.title.slice(0, 25);
                    break;
                case 'tv':
                    return this.movie.name.slice(0, 25);
                    break;
                case 'person':
                    return this.movie.name.slice(0, 25);
                    break;
            }
        },
        getResultSubTitle() {
            switch (this.getResultType) {
                case 'movie':
                    return new Date(this.movie.release_date).toLocaleString('en-US', { year: 'numeric', });
                    break;
                case 'tv':
                    return new Date(this.movie.first_air_date).toLocaleString('en-US', { year: 'numeric', });
                    break;
                case 'person':
                    return this.movie.known_for_department.slice(0, 25);
                    break;
            }
        },
        getResultYear() {
            switch (this.getResultType) {
                case 'movie':
                    const release_date = new Date(this.movie.release_date).toLocaleString('en-US', { year: 'numeric', });
                    return '-' + release_date;
                    break;
                case 'tv':
                    const first_air_date = Date(this.movie.first_air_date).toLocaleString('en-US', { year: 'numeric', });
                    return '-' + first_air_date;
                    break;
                case 'person':
                    return '';
                    break;
            }
        },
        getResultNuxtLink() {
            switch (this.getResultType) {
                case 'movie':
                    return 'movies-slug';
                    break;
                case 'tv':
                    return 'tvshows-slug';
                    break;
                case 'person':
                    return 'actors-slug';
                    break;
            }
        },




        closeSearchResults() {
            this.$emit('closeSearchResults') // closes the mobile menu if in open state and when route changes
        }
    },

}
