export default function ({ store, route, redirect }) {
    //Redirect for old movie eg. http://localhost:3000/movie/the-linen-closet-2015
    //or http://localhost:3000/movie/460330/La+verginella-1975 
    const searchID = route.params.id ? route.params.id : '';
    if (searchID > 0) {
        redirect(`/movies/${searchID}`);
        return
    }
    const urlParameters = route.params.slug.split('-');
    let lastURLPart = parseInt(urlParameters.pop());
    let searchSlug = '';
    if (lastURLPart.length == 4 && lastURLPart > 1900 && lastURLPart < 2050) {
        searchSlug = urlParameters.slice(0, -1).join(' ');
    } else {
        searchSlug = urlParameters.join(' ');
    }
    const searchSlugEncoded = encodeURIComponent(searchSlug);
    return store.$axios.get(`https://api.themoviedb.org/3/search/movie/?query=${searchSlugEncoded}&api_key=${process.env.apikey}&language=${process.env.lang}&include_adult=false`)
        .then((result) => {
            const result_id = result.data.results[0].id;
            const result_title = result.data.results[0].title;
            const result_year = new Date(result.data.results[0].release_date).toLocaleString('en-US', { year: 'numeric', });
            const redirectUrl = '/movies/' + store.$slugy(result_id + '-' + result_title + '-' + result_year)
            //console.log(redirectUrl);
            redirect(redirectUrl);
            return
        }).catch((err) => {
            redirect('/movies/');
        })
}