const middleware = {}

middleware['redirects-actor'] = require('../middleware/redirects-actor.js')
middleware['redirects-actor'] = middleware['redirects-actor'].default || middleware['redirects-actor']

middleware['redirects-movie'] = require('../middleware/redirects-movie.js')
middleware['redirects-movie'] = middleware['redirects-movie'].default || middleware['redirects-movie']

middleware['redirects-movies-keywords'] = require('../middleware/redirects-movies-keywords.js')
middleware['redirects-movies-keywords'] = middleware['redirects-movies-keywords'].default || middleware['redirects-movies-keywords']

middleware['redirects-news'] = require('../middleware/redirects-news.js')
middleware['redirects-news'] = middleware['redirects-news'].default || middleware['redirects-news']

middleware['redirects-tv'] = require('../middleware/redirects-tv.js')
middleware['redirects-tv'] = middleware['redirects-tv'].default || middleware['redirects-tv']

export default middleware
