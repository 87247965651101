export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const Articles = () => import('../../components/Articles.vue' /* webpackChunkName: "components/articles" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GoogleAd = () => import('../../components/GoogleAd.vue' /* webpackChunkName: "components/google-ad" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MovieCard = () => import('../../components/MovieCard.vue' /* webpackChunkName: "components/movie-card" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchCard = () => import('../../components/SearchCard.vue' /* webpackChunkName: "components/search-card" */).then(c => wrapFunctional(c.default || c))
export const TVCard = () => import('../../components/TVCard.vue' /* webpackChunkName: "components/t-v-card" */).then(c => wrapFunctional(c.default || c))
export const CardsActorCard = () => import('../../components/cards/ActorCard.vue' /* webpackChunkName: "components/cards-actor-card" */).then(c => wrapFunctional(c.default || c))
export const CardsBookmark = () => import('../../components/cards/Bookmark.vue' /* webpackChunkName: "components/cards-bookmark" */).then(c => wrapFunctional(c.default || c))
export const CardsEpisodeCard = () => import('../../components/cards/EpisodeCard.vue' /* webpackChunkName: "components/cards-episode-card" */).then(c => wrapFunctional(c.default || c))
export const CardsEpisodeCardSmall = () => import('../../components/cards/EpisodeCardSmall.vue' /* webpackChunkName: "components/cards-episode-card-small" */).then(c => wrapFunctional(c.default || c))
export const CardsGenreCard = () => import('../../components/cards/GenreCard.vue' /* webpackChunkName: "components/cards-genre-card" */).then(c => wrapFunctional(c.default || c))
export const CardsMediaCard = () => import('../../components/cards/MediaCard.vue' /* webpackChunkName: "components/cards-media-card" */).then(c => wrapFunctional(c.default || c))
export const CardsMediaCardRecent = () => import('../../components/cards/MediaCardRecent.vue' /* webpackChunkName: "components/cards-media-card-recent" */).then(c => wrapFunctional(c.default || c))
export const CardsMediaCardResponsive = () => import('../../components/cards/MediaCardResponsive.vue' /* webpackChunkName: "components/cards-media-card-responsive" */).then(c => wrapFunctional(c.default || c))
export const CardsMediaCardSmall = () => import('../../components/cards/MediaCardSmall.vue' /* webpackChunkName: "components/cards-media-card-small" */).then(c => wrapFunctional(c.default || c))
export const CardsNewsCard = () => import('../../components/cards/NewsCard.vue' /* webpackChunkName: "components/cards-news-card" */).then(c => wrapFunctional(c.default || c))
export const CardsVideoCard = () => import('../../components/cards/VideoCard.vue' /* webpackChunkName: "components/cards-video-card" */).then(c => wrapFunctional(c.default || c))
export const MiscBannerOverlay = () => import('../../components/misc/BannerOverlay.vue' /* webpackChunkName: "components/misc-banner-overlay" */).then(c => wrapFunctional(c.default || c))
export const MiscImageOverlay = () => import('../../components/misc/ImageOverlay.vue' /* webpackChunkName: "components/misc-image-overlay" */).then(c => wrapFunctional(c.default || c))
export const MiscLoadingOverlay = () => import('../../components/misc/LoadingOverlay.vue' /* webpackChunkName: "components/misc-loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAddWatchlistButton = () => import('../../components/buttons/AddWatchlistButton.vue' /* webpackChunkName: "components/buttons-add-watchlist-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsAddWatchlistIcon = () => import('../../components/buttons/AddWatchlistIcon.vue' /* webpackChunkName: "components/buttons-add-watchlist-icon" */).then(c => wrapFunctional(c.default || c))
export const ButtonsRentOnline = () => import('../../components/buttons/RentOnline.vue' /* webpackChunkName: "components/buttons-rent-online" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/nav/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NavMenu = () => import('../../components/nav/NavMenu.vue' /* webpackChunkName: "components/nav-menu" */).then(c => wrapFunctional(c.default || c))
export const NavWatchlist = () => import('../../components/nav/Watchlist.vue' /* webpackChunkName: "components/nav-watchlist" */).then(c => wrapFunctional(c.default || c))
export const PageContentBottomBar = () => import('../../components/pageContent/BottomBar.vue' /* webpackChunkName: "components/page-content-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const PageContentMediaPoster = () => import('../../components/pageContent/MediaPoster.vue' /* webpackChunkName: "components/page-content-media-poster" */).then(c => wrapFunctional(c.default || c))
export const PageContentOverview = () => import('../../components/pageContent/Overview.vue' /* webpackChunkName: "components/page-content-overview" */).then(c => wrapFunctional(c.default || c))
export const PageContentRatingWheel = () => import('../../components/pageContent/RatingWheel.vue' /* webpackChunkName: "components/page-content-rating-wheel" */).then(c => wrapFunctional(c.default || c))
export const PageContentTrailerDialog = () => import('../../components/pageContent/TrailerDialog.vue' /* webpackChunkName: "components/page-content-trailer-dialog" */).then(c => wrapFunctional(c.default || c))
export const SlidersActorCarousel = () => import('../../components/sliders/ActorCarousel.vue' /* webpackChunkName: "components/sliders-actor-carousel" */).then(c => wrapFunctional(c.default || c))
export const SlidersBannerCarousel = () => import('../../components/sliders/BannerCarousel.vue' /* webpackChunkName: "components/sliders-banner-carousel" */).then(c => wrapFunctional(c.default || c))
export const SlidersEpisodeSlider = () => import('../../components/sliders/EpisodeSlider.vue' /* webpackChunkName: "components/sliders-episode-slider" */).then(c => wrapFunctional(c.default || c))
export const SlidersImageCarousel = () => import('../../components/sliders/ImageCarousel.vue' /* webpackChunkName: "components/sliders-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const SlidersImageSlider = () => import('../../components/sliders/ImageSlider.vue' /* webpackChunkName: "components/sliders-image-slider" */).then(c => wrapFunctional(c.default || c))
export const SlidersMediaCarousel = () => import('../../components/sliders/MediaCarousel.vue' /* webpackChunkName: "components/sliders-media-carousel" */).then(c => wrapFunctional(c.default || c))
export const SlidersVideoSlider = () => import('../../components/sliders/VideoSlider.vue' /* webpackChunkName: "components/sliders-video-slider" */).then(c => wrapFunctional(c.default || c))
export const NavSearch = () => import('../../components/nav/search/Search.vue' /* webpackChunkName: "components/nav-search" */).then(c => wrapFunctional(c.default || c))
export const NavSearchSmall = () => import('../../components/nav/search/SearchSmall.vue' /* webpackChunkName: "components/nav-search-small" */).then(c => wrapFunctional(c.default || c))
export const PageContentPagesActor = () => import('../../components/pageContent/pages/Actor.vue' /* webpackChunkName: "components/page-content-pages-actor" */).then(c => wrapFunctional(c.default || c))
export const PageContentPagesMovie = () => import('../../components/pageContent/pages/Movie.vue' /* webpackChunkName: "components/page-content-pages-movie" */).then(c => wrapFunctional(c.default || c))
export const PageContentPagesMovieSmall = () => import('../../components/pageContent/pages/MovieSmall.vue' /* webpackChunkName: "components/page-content-pages-movie-small" */).then(c => wrapFunctional(c.default || c))
export const PageContentPagesTv = () => import('../../components/pageContent/pages/Tv.vue' /* webpackChunkName: "components/page-content-pages-tv" */).then(c => wrapFunctional(c.default || c))
export const PageContentPagesTvSmall = () => import('../../components/pageContent/pages/TvSmall.vue' /* webpackChunkName: "components/page-content-pages-tv-small" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
