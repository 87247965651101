
import { mapState, mapActions } from 'vuex'
import { mdiBookmarkPlus, mdiBookmarkRemove, mdiVideoImage } from '@mdi/js'

export default {
  name: 'Watchlist',
  data() {
    return {
      svgBookmarkPlus: mdiBookmarkPlus,
      svgBookmarkRemove: mdiBookmarkRemove,
      svgVideoImage: mdiVideoImage,
    }
  },
  computed: {
    ...mapState(["watchlist"]),
  },
  methods: {
    ...mapActions(["removeFromWatchlist"]),
    releasedYear(media) {
      if (media.release_date) {
        const date = media.release_date.toString()
        return date.slice(0, 4)
      }
      if (media.first_air_date) {
        const date = media.first_air_date.toString()
        return date.slice(0, 4)
      }
    },
    mediaTitle(media) {
      return media.name ? media.name : media.title
    },
    mediaType(media) {
      if (media.media_type) {
        return media.media_type
      } else if (media.first_air_date) {
        return "tvshows"
      } else if (media.known_for_department) {
        return "actors"
      } else {
        return "movies"
      }

    },
    mediaImage(media) {
      return media.poster_path ? media.poster_path : media.profile_path
    },
    mediaURL(media) {
      if (this.mediaType(media) == 'actors') {
        return this.$slugy(media.id + '-' + this.mediaTitle(media))
      }
      return this.$slugy(media.id + '-' + this.mediaTitle(media) + '-' + this.releasedYear(media))

    }
  },
}
