/* Converts string to url slug*/
export default (context, inject) => {
    const slugy = (msg) => {
        return msg
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
    }

    // Inject $hello(msg) in Vue, context and store.
    inject('slugy', slugy)
    /* // For Nuxt <= 2.12, also add 👇
      context.$slugy = slugy */
}