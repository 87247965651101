
import NavBar from "~/components/nav/NavBar.vue";
export default {
	name: "DefaultLayout",
	components: {
		NavBar
	},
	transition: {
		name: "",
		mode: ""
	},
	watch: {
		$route() {
			this.showMobileMenu = false;
		}
	},
	data() {
		return {
			showMobileMenu: false
		};
	}
};
