export default function ({ store, route, redirect }) {
    //Redirect for old actor eg. http://localhost:3000/en/actor/56731/Jessica%20Alba
    // /actor/135461/Andy+San+Dimas
    const searchID = route.params.id ? route.params.id : '';
    const searchSlug = route.params.slug ? route.params.slug : '';

    if (searchID > 0) {
        redirect(`/actors/${searchID}`);
        return
    }
    const searchSlugEncoded = encodeURIComponent(searchSlug);
    return store.$axios.get(`https://api.themoviedb.org/3/search/person/?query=${searchSlugEncoded}&api_key=${process.env.apikey}&language=${process.env.lang}&include_adult=false`)
        .then((result) => {
            const result_id = result.data.results[0].id;
            const result_title = result.data.results[0].name;
            const redirectUrl = '/actors/' + store.$slugy(result_id + '-' + result_title)
            //console.log(redirectUrl);
            redirect(redirectUrl);
            return
        }).catch((err) => {
            redirect('/actors/');
        })
}