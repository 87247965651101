
import { mdiFacebook, mdiYoutube } from '@mdi/js'
export default {
  name: 'Footer',
  data() {
    return {
      svgFacebook: mdiFacebook,
      svgYoutube: mdiYoutube,
      socialIcons: [
        { title: 'Facebook', icon: this.svgFacebook, url: 'https://www.facebook.com/movietube.movies' },
        { title: 'Youtube', icon: this.svgYoutube, url: 'https://www.youtube.com/channel/UCdwL6_9lRnfh4B5muNIjYcQ' },
      ],
      links: [
        { title: 'About', url: '/about' },
        { title: 'Contact', url: '/contact' },
        { title: 'Cookies', url: '/cookies' },
        { title: 'Terms of use', url: '/terms' },
        { title: 'Privacy Policy', url: '/privacy-policy' },

      ]
    }
  }
}
